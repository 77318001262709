.card-block {
    .image-right {
        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }

    @include transition($transition-navbar);

    &:hover {
        img {
            transform: scale(1.01);
        }
    }
}
