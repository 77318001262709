.footer {
    a.navbar-brand {
        &,
        & b {
            font-size: 12px;
            line-height: 1.2;
            font-weight: bold;

            &,
            &:hover {
                color: $white !important
            }
        }

        .brand-logo {
            height: 2rem;
            border-right: 1px solid $white;
            padding-right: .5rem;
            margin-right: .5rem;
        }
    }

    .logo,
    .social-list {
        margin-bottom: 0;
    }

    p {
        padding-bottom: 0;
    }

    .social-list {
        @include media-breakpoint-down(lg) {
            text-align: right !important;
        }

        @include media-breakpoint-down(sm) {
            text-align: left !important;
            margin-top: 1rem;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}