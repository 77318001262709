/* Custom theme below */

$primary-color:             #c35444;
$secondary-color:           #f5f5fa;
$text-color:                #2d3135;
$background-color:          #ffffff;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "text": $text-color,
    "white": #fff
)