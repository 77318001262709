.brand-logo {
    height: 2.875rem;

    &-s {
        height: 2rem;
    }

    .header-wrapper &,
    .header-wrapper &-s {
        @include media-breakpoint-down(md) {
            height: 30px;
            max-width: 100%;
        }
    }
}

.outerlink {
    font-size: 14px;
    font-weight: 400;

    img {
        background: $theme-mqb;
        padding: 8px 4px;
        margin-left: .5rem;
        height: 36px;

        @include media-breakpoint-down(md) {
            height: 28px;
        }
    }

    a:hover {
        text-decoration: none;
    }
}

.searchbar-wrapper {
    .input-group {
        border-radius: 2rem;
        border: 2px solid $border-color;
        height: 2.25rem;
    }

    input {
        background: transparent;
        height: 2rem;
        border: 0;
        padding: 0;
    }

    i {
        color: $border-color;
    }
}

.navbar-header {
    &,
    & .navbar-nav a.nav-link {
        height: 3rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none !important;
        }
    }

    .navbar-primary {
        border-top: 1px solid #ececec;
        background-color: $white !important;

        &.navigation-default .submenu li {
            padding: .5rem 1rem;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
        }
    }

    @include media-breakpoint-down(md) {
        height: 0;
    }

    .submenu {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &:hover {
            background-color: $secondary-color !important;
        }

        li {
            &:hover {
                background-color: darken($secondary-color, 10) !important;
            }
        }
    }

    .nav-item.has-children:hover {
        .submenu {
            display: block;
        }
    }
}

.navbar-nav {
    .nav-item {
        .nav-link {
            text-transform: uppercase;
            font-weight: 600;
            padding: 0 1rem;
            position: relative;

            &:after {
                @include media-breakpoint-up(lg) {
                    content: '';
                    background-color: $primary-color;
                    height: 4px;
                    position: absolute;
                    bottom: 1px;
                    @include transition(all 200ms ease-in-out);
                    left: 0;
                    right: 100%;
                }
            }
        }

        &.active,
        &:hover {
            .nav-link {
                &:after {
                    left: 1rem;
                    right: .5rem;
                }
            }
        }
    }
}

.header-wrapper {
    border-bottom: 1px solid $secondary-color;
    box-shadow: 0px 1px 3px 0px $border-color;

    .social-list {
        .list-inline-item {
            margin-right: 0;

            a {
                border: 2px solid $border-color;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .nav-brand {
        display: none;
        padding: 8px 4px;
        background-color: $primary-color;
        height: 3rem;
    }

    &.sticky {
        .hidden-sticky {
            display: none !important;
        }

        @include media-breakpoint-up(lg) {
            .nav-brand {
                display: block;
            }

            .hidden-md-sticky {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar-header .navbar-primary.navigation-default {
        &.collapse.show, &.collapsing {
            top: 0 !important;
        }
    }
}