.articlelayout {
    .paragraph {
        ul {
            list-style: none;
            padding-left: 0;

            li {
                margin-bottom: .5rem;
                padding-left: 24px;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    background-color: $primary-color;
                    height: 2px;
                    width: 10px;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    color: $primary-color;
                }
            }
        }
    }

    h2 {
        color: $primary-color;
    }
}

.article-title {
    padding: 1rem;
    background-color: rgba($text-color, .6);

    @include media-breakpoint-up(md) {
        padding: 2rem 4rem;
    }

    h1 {
        font-size: 36px;
        font-weight: 300;

        strong {
            font-weight: 500;
        }
    }
}
