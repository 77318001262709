.cloud-liner {
    &:after {
        border-color: $primary-color;
    }

    &.half:after {
        top: 0;
        left: unset;
        right: 0;
        width: 50%;
        border-top-width: .5rem;;
    }
}