html {
    scroll-behavior: smooth;
}

body {
    font-family: $font-sans;
    font-weight: 300;

    &.lb-disable-scrolling {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4 {
    font-family: $font-sans;
    line-height: 1.1;
}

h2 {
    font-weight: 400;
}

p {
    font-family: $font-sans;
    line-height: 1.5;
}

strong {
    font-weight: 600;
}

.vCenter {
    display: flex;
    align-items: center;
}

:focus {
    outline: none;
}

.btn:focus {
    outline: 0;
    box-shadow: none;
}

input,
button,
.btn,
.form-control {
    &:focus {
        outline: none;
        box-shadow: 0;
    }
}

.paragraph a {
    text-decoration: none;
}

a {
    .paragraph &,
    p &,
    h3 & {
        text-decoration: none;

        &:hover {
            text-decoration: underline !important;
        }
    }

    &,
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.bb-site-wrapper {
    .main {
        padding-top: 162px;

        @include media-breakpoint-down(md) {
            padding-top: 88px;
        }
    }

    .lead {
        font-size: 22px;
        font-weight: 300;
        line-height: 1.3;
    }

    .text-mll-small {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3;
    }
}

.no-gutters.row .bb-content.paragraph {
    padding: 2rem 4rem;
}

.figure.media-image .contentwrapper .above-content-wrapper {
    padding: 0 .5rem;
    background-color: rgba(0,0,0,.2);
}

// Menu Toggle
.navbar-toggler {
    position: relative;
    border-color: transparent;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    z-index: 1000;
    @include transition($transition-navbar);

    &:focus {
        outline: none;
    }

    .icon-bar {
        position: absolute;
        top: 50%;
        height: 2px;
        left: 50%;
        width: 50%;
        background-color: $navbar-toggler-color;
        @include translate3d(-50%, 0, 0);
        @include transition($transition-navbar);

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $navbar-toggler-color;
            border-radius: 1px;
        }
        &:before {
            top: -7px;
        }

        &:after {
            top: 7px;
        }
    }

    &:not(.collapsed) {
        .icon-bar {
            background: transparent !important;

            &:before{
                @include transition($transition-navbar);
                -moz-transform: rotate(45deg) translate(5px,5px);
                -webkit-transform: rotate(45deg) translate(5px,5px);
                -o-transform: rotate(45deg) translate(5px,5px);
                -ms-transform: rotate(45deg) translate(5px,5px);
                transform: rotate(45deg) translate(5px,5px);
            }

            &:after{
                @include transition($transition-navbar);
                -moz-transform: rotate(-45deg) translate(5px,-5px);
                -webkit-transform: rotate(-45deg) translate(5px,-5px);
                -o-transform: rotate(-45deg) translate(5px,-5px);
                -ms-transform: rotate(-45deg) translate(5px,-5px);
                transform: rotate(-45deg) translate(5px,-5px);
            }
        }
    }
}

.row-overlay:after {
    background-color: rgba(0,0,0,.7);
}

.lightbox .lb-image {
    border: 0 !important;
}

.articlelayout .btn-outline.btn.color-primary:focus {
    color: #c35444 !important;
    border-color: #c35444;
    background-color: #ffffff;
}

.lightbox .lb-number {
    display: none !important;
}

// Cloud
.is-desktop .cloud-edit.add-padding .navbar,
.is-desktop .cloud-edit.add-padding .navbar .navbar-primary {
    padding-left: 0 !important;
}

.is-desktop .cloud-edit.add-padding .header-wrapper {
    padding-left: 80px;
}

.cloud-ui-wrapper .image-list .item .figure {
    height: 121px !important;
}

.cke_float {
    z-index: 10001 !important;
}